import store from '../store'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

class Model {
  constructor(obj) {
    this.name = obj.name
    this.scene = obj.scene
    this.files = obj.files
    this.placeOnLoad = obj.onLoad

    this.size = obj.size
    this.pos = obj.pos
    this.isReady = false
    this.loader = new GLTFLoader()
    this.dracoLoader = new DRACOLoader()
    this.dracoLoader.setDecoderPath('./draco/')
    this.loader.setDRACOLoader(this.dracoLoader)
  }

  init() {
    return new Promise((resolve, reject) => {
      const loader = new THREE.TextureLoader()
      const bg = './images/baked.jpg'
      const { breakpoints } = store

      this.files.forEach((file) => {
        this.group = new THREE.Group()
        this.group.name = this.name

        this.loader.load(file, (res) => {
          /*------------------------------
          Material Mesh
          ------------------------------*/

          let material = new THREE.MeshMatcapMaterial({
            matcap: loader.load(bg),
          })

          /*------------------------------
          Original Mesh
          ------------------------------*/
          let mesh = res.scene.children[0]
          let geometry = mesh.geometry
          let pillow = new THREE.Mesh(geometry, material)
          let scale

          if (breakpoints.M_UP) {
            scale = 0.14
          } else {
            scale = 0.1
          }

          pillow.position.z = 6.2
          pillow.position.x = 2
          this.group.scale.set(scale, scale, scale)

          this.group.position.y = 0.5
          this.group.rotation.x = 1.4

          this.group.add(pillow)
          this.isReady = true

          resolve()
        })
      })
    })
  }

  resize() {
    const { breakpoints } = store
    let scale

    if (breakpoints.M_UP) {
      scale = 0.14
    } else {
      scale = 0.1
    }

    this.group.scale.set(scale, scale, scale)
  }

  add() {
    this.scene.add(this.group)
  }

  remove() {
    this.scene.remove(this.group)
  }
}

export default Model
