import store from '../store'
import gsap from 'gsap'
import { Emitter } from '../core'

export const animateIn = (animation) => {
  switch (animation) {
    case 'hero':
      const hero = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')

          const tl = gsap.timeline({
            repeat: -1,
          })
        },
      })

      hero.from('.preload-sticker', {
        duration: 3,
        rotate: -360 * 2,
        ease: 'power3.inOut',
      })

      hero.to(
        '.svg-mask',
        {
          duration: 1,
          scale: 0,
          rotate: 50,
        },
        '-=2.125',
      )

      hero.to(
        '.preload-sticker',
        {
          duration: 1.2,
          scale: 0.55,
          y: -(store.sizes.vh / 2),
          x: -(store.sizes.vw / 2),
          ease: 'power2.inOut',
          autoAlpha: 0,
          onComplete: () => {
            gsap.to('.preload', { autoAlpha: 0 })
          },
        },
        '-=2.25',
      )

      hero.to(
        '.preload-sticker .circle',
        { duration: 1, fill: '#141414' },
        '-=2',
      )
      hero.to(
        '.preload-sticker .face',
        { duration: 1, stroke: '#CEC0AD' },
        '-=2',
      )
      hero.to(
        '.preload-sticker .stroke',
        { duration: 1, stroke: '#CEC0AD' },
        '-=2',
      )

      hero.from(
        '.header .header-logo',
        {
          duration: 0.5,
          autoAlpha: 0,
          y: '50%',
          x: '50%',
          ease: 'power3,inOut',
        },
        '-=1.45',
      )

      hero.to('.hero .top', { duration: 1, y: '-101%' }, '-=1.5')
      hero.to('.hero .bottom', { duration: 1, y: '101%' }, '-=1.5')
      hero.to('.hero .left', { duration: 1, x: '-101%' }, '-=1.5')
      hero.to('.hero .right', { duration: 1, x: '101%' }, '-=1.5')
      hero.from('.header-bg', { duration: 1, y: '-101%' }, '=-0.5')
      hero.from('.hero-shape', { duration: 1, y: '-101%' }, '-=0.7')
      hero.from('.shape-crop', { duration: 1, y: '101%' }, '=-1')

      hero.from(
        '.title-txt',
        {
          duration: 1,
          autoAlpha: 0,
          y: 40,
          stagger: 0.1,
        },
        '-=1',
      )

      hero.from('.hero-marquee', { duration: 0.8, y: '101%' }, '-=0.6')
      hero.from('.hero .marquee-item', { duration: 0.8, y: '-101%' }, '-=0.8')
      hero.from('.hero-icon', { duration: 1.2, scale: 0 }, '=-1.5')
      hero.from('.hero-arrow svg', { duration: 1, y: '-101%' }, '-=2')
      hero.from('.hero-bar', { duration: 1, y: '201%' }, '-=2')

      hero.play()

      break
  }
}
