import store from '../store'
import * as THREE from 'three'
import { Emitter } from '../core'
import LoadModel from './LoadModel'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'
import { bounds } from '../utils'

export default class PillowAbout {
  constructor(obj = {}) {
    const container = obj.container || store.body
    const { sniff } = store

    this.scene = {
      name: 'Default',
      renderer: null,
      camera: null,
      objects: null,
      orbit: null,
      container,
      multiplier: sniff.isDesktop ? 350 : 100,
    }

    this.state = {
      active: false,
    }

    this.mouse = {}

    this.time = 0
    this.init()
  }

  setup() {
    this.render()
    this.camera()
    this.mesh()
  }

  render() {
    const { container, multiplier } = this.scene
    const { sizes } = store
    const dpr = window.devicePixelRatio ? window.devicePixelRatio : 1
    const rect = bounds(container)
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      setPixelRatio: dpr,
    })

    renderer.setClearColor(0x000000, 0)
    renderer.domElement.classList.add('gl-pillow')
    renderer.setSize(rect.width + multiplier, rect.height + multiplier)

    //    renderer.domElement.style.width = rect.width
    //   renderer.domElement.style.height = rect.height

    container.appendChild(renderer.domElement)

    Object.assign(this.scene, {
      renderer,
      rect,
    })
  }

  camera() {
    const { sizes } = store
    const { rect, multiplier } = this.scene
    const aspectRatio = rect.width + multiplier / rect.height + multiplier
    const camera = new THREE.PerspectiveCamera(35, aspectRatio, 0.1, 100)

    camera.position.set(0, 1, 7)
    camera.lookAt(0, 0, 0)

    Object.assign(this.scene, {
      camera,
    })
  }

  mesh() {
    const objects = new THREE.Scene()

    const pillow = new LoadModel({
      name: 'Pillow',
      files: ['./models/bubble.glb'],
      scene: objects,
    })

    pillow.init().then(() => {
      pillow.add()
    })

    Object.assign(this.scene, {
      objects,
      pillow,
    })
  }

  tick = ({ mouse }) => {
    const { renderer, camera, objects } = this.scene

    if (!this.state.active) return

    this.mouse = mouse
    this.time += 1 / 300
    renderer.render(objects, camera)
  }

  visible() {
    const { container } = this.scene
    const { objects } = this.scene
    const { sniff } = store

    ScrollTrigger.create({
      trigger: '.about',
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      scrub: true,
      onEnter: () => {
        this.state.active = true
      },
      onEnterBack: () => {
        this.state.active = true
      },
      onLeaveBack: () => {
        this.state.active = false
      },
      onLeave: () => {
        this.state.active = false
      },
    })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.about',
        start: 'top top+=45%',
        end: 'bottom bottom+=5%',
        scrub: true,
        once: true,
      },
    })

    tl.from(objects.scale, {
      y: 0,
      x: 0,
      z: 0,
    })

    tl.to(
      objects.position,
      {
        y: sniff.isDesktop ? 0.15 : 0,
        x: -0.15,
        z: 0,
      },
      0,
    )
  }

  move = () => {
    const { sizes } = store
    const { objects } = this.scene

    const x = this.mouse.x
    const y = this.mouse.y
    const xrange = gsap.utils.mapRange(0, sizes.vw, 0.2, -0.2, x)
    const yrange = gsap.utils.mapRange(0, sizes.vh, 0.2, -0.2, y)

    gsap.to(objects.rotation, {
      y: xrange,
      x: yrange,
    })
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.resize)
    window.addEventListener('mousemove', this.move)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('resize', this.resize)
    window.removeEventListener('mousemove', this.move)
  }

  resize = () => {
    const { sizes, breakpoints } = store
    const { pillow, container, multiplier } = this.scene
    const rect = bounds(container)
    const aspectRatio = (rect.width + multiplier) / (rect.height + multiplier)

    pillow.resize()

    this.scene.camera.aspect = aspectRatio
    this.scene.camera.updateProjectionMatrix()
    this.scene.renderer.setSize(
      rect.width + multiplier,
      rect.height + multiplier,
    )
  }

  init() {
    this.setup()
    this.on()
    this.visible()
    this.resize()
  }
}
