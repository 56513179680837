import store from '../store'
import Emitter from './Emitter'
import Lenis from '@studio-freight/lenis'

export default class Scroll {
  constructor() {
    const { body } = store

    body.classList.add('has-lenis')

    this.scroll = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })

    this.scroll.scrollTo(0)
  }

  raf = (time) => {
    //Wanted to have only one raf
    this.scroll.raf(time)
    requestAnimationFrame(this.raf)
  }

  tick = ({ time, deltaTime, frame }) => {
    // Time needs to be * 1000 if not too slow
    this.scroll.raf(time * 1000)
  }

  on() {
    this.scroll.on(
      'scroll',
      ({ scroll, limit, velocity, direction, progress }) => {
        Emitter.emit('scroll', { scroll, limit, velocity, direction, progress })
      },
    )

    Emitter.on('tick', this.tick)
    // requestAnimationFrame(this.raf)
  }
}
