import { animateIn, animateOut, animateScroll } from '../animations'
import { qsa } from '../utils'

export default class AnimationManager {
  constructor() {
    const animations = qsa('[data-anim]')

    this.animIn = animations.filter((a) => {
      if (a.dataset.anim == 'i') return a
    })

    this.animOut = animations.filter((a) => {
      if (a.dataset.anim == 'o') return a
    })

    this.animScroll = animations.filter((a) => {
      if (a.dataset.anim == 's') return a
    })
  }

  in() {
    if (!this.animIn[0]) return

    this.animIn.forEach((anim) => {
      animateIn(anim.id)
    })
  }

  out() {
    if (!this.animOut[0]) return

    this.animOut.forEach((anim) => {
      animateOut(anim.id)
    })
  }

  onScroll() {
    if (!this.animScroll[0]) return

    this.animScroll.forEach((anim) => {
      animateScroll(anim.id)
    })
  }
}
