import store from '../store'
import { qs, qsa } from '../utils'
import gsap from 'gsap'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class Slider {
  constructor() {
    this.container = qs('.work-cards')
    this.els = qsa('.cards-item', this.container)

    this.state = {
      start: 0,
      current: 0,
      end: 0,
      down: false,
      direction: null,
      animate: false,
      move: false,
      sound: true,
      isvisible: false,
    }

    this.slides = {
      last: this.els.length - 1,
      first: 0,
      active: 0,
      total: this.els.length,
      current: null,
      sound: null,
      icon: qs('.header-sound'),
    }

    this.init()
  }

  setup() {
    const total = this.els.length
    const curVideo = qs('video', this.els[0])
    const { sniff } = store

    this.els.forEach((card, i) => {
      const video = qs('video', card)

      // if (sniff.isDevice) video.muted = true
      gsap.set(card, { zIndex: total - i })
    })

    this.slides.current = curVideo
  }

  playSound() {
    const tl = gsap.timeline({
      repeat: -1,
      paused: true,
    })

    tl.to('.sound-line', {
      duration: 0.6,
      y: 8,
      stagger: {
        each: 0.1,
        from: 'random',
        ease: 'power2.inOut',
      },
    })

    tl.to(
      '.sound-line',
      {
        duration: 0.6,
        y: 0,
        stagger: {
          each: 0.1,
          from: 'random',
          ease: 'power2.inOut',
        },
      },
      '-=0.1',
    )

    Object.assign(this.slides, {
      sound: tl,
    })
  }

  onDown = (e) => {
    this.state.down = true
    this.state.start = e.clientX || e.touches[0].clientX
    this.container.classList.add('is-grabbing')
  }

  onMove = (e) => {
    const { down, start, end } = this.state
    if (!down || start == end) return
    this.state.current = e.clientX || e.touches[0].clientX
    this.state.move = true
  }

  onUp = (e) => {
    if (this.state.animate || !this.state.move) return
    const { active, last } = this.slides
    this.state.down = false

    this.state.end = e.clientX || e.changedTouches[0].clientX

    const current = this.state.start - this.state.end

    this.container.classList.remove('is-grabbing')

    if (current > 0) {
      this.state.direction = 'left'
      this.slides.active = active < last ? active + 1 : 0
    } else {
      this.state.direction = 'right'
      this.slides.active = active > 0 ? active - 1 : last
    }

    this.state.move = false
    this.changeSlide()
  }

  changeSlide() {
    this.state.animate = true
    const { sniff } = store
    const { direction } = this.state
    const { active, last, total } = this.slides
    const prev = active - 1 >= 0 ? active - 1 : last
    const next = active + 1 <= last ? active + 1 : 0
    const curr = this.els[active]
    const back = this.els[prev]
    const videos = qsa('video', this.container)
    const curVideo = qs('video', curr)

    this.slides.current = curVideo

    videos.forEach((video) => {
      video.pause()
      video.muted = true
    })

    curVideo.play()

    if (this.state.sound && sniff.isDesktop) curVideo.muted = false

    const tl = gsap.timeline({
      onComplete: () => {
        this.state.animate = false
      },
    })

    if (direction == 'left') {
      tl.to(back, { duration: 0.5, x: '-100%', rotate: -15 })
      tl.to(back, { duration: 0.5, x: '0%', rotate: -15 })
      tl.to(this.els[active], { duration: 0.5, rotate: 0 }, '-=1')
    } else {
      tl.to(curr, { duration: 0.5, x: '100%', rotate: 15 })
      tl.to(curr, { duration: 0.5, x: '0%', rotate: -15 })
      tl.to(curr, { duration: 0.5, rotate: 0, delay: 0.5 }, '-=1')
    }

    this.els.forEach((item, i) => {
      const index = total - i
      const module = (active + index) % total
      const bound = module == 0 ? total : module
      const a = i % 2 == 0 ? 'odd' : 'even'

      if (i !== active) {
        if (a == 'odd')
          tl.to(item, { duration: 0.5, rotate: 15, delay: 0.5 }, '-=1')
        if (a == 'even')
          tl.to(item, { duration: 0.5, rotate: -15, delay: 0.5 }, '-=1')
      }

      gsap.set(item, { zIndex: bound, delay: 0.5 })
    })
  }

  visible() {
    const sticky = qs('.work-content')
    const { sound } = this.slides
    const { sniff } = store

    ScrollTrigger.create({
      trigger: '.work',
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,
      onEnter: () => {
        gsap.set(sticky, { top: 0 })
        sticky.classList.add('-fixed')
      },
      onEnterBack: () => {
        gsap.set(sticky, { top: 0 })
        sticky.classList.add('-fixed')
      },
      onLeaveBack: () => {
        sticky.classList.remove('-fixed')
      },
      onLeave: () => {
        gsap.set(sticky, { top: '100vh' })
        sticky.classList.remove('-fixed')
      },
    })

    ScrollTrigger.create({
      trigger: '.work',
      start: 'top top',
      end: 'bottom bottom',

      onEnter: () => {
        this.slides.current.play()
        this.state.isvisible = true

        if (sniff.isDesktop) {
          if (this.state.sound) sound.play()
          //  if (this.state.sound) this.slides.current.muted = false
        }
      },

      onEnterBack: () => {
        this.slides.current.play()
        this.state.isvisible = true

        if (sniff.isDesktop) {
          if (this.state.sound) sound.play()
          //  if (this.state.sound) this.slides.current.muted = false
        }
      },

      onLeave: () => {
        this.slides.current.pause()
        this.state.isvisible = false

        if (sniff.isDesktop) {
          sound.pause()

          if (this.state.sound) this.slides.current.muted = true
        }
      },

      onLeaveBack: () => {
        this.slides.current.pause()
        this.state.isvisible = false

        if (sniff.isDesktop) {
          if (this.state.sound) sound.pause()
          if (this.state.sound) this.slides.current.muted = true
        }
      },
    })
  }

  toggleSound = () => {
    const { sound } = this.slides
    const videos = qsa('video', this.container)

    if (this.state.sound) {
      this.state.sound = false
      this.slides.current.muted = true
      sound.pause()
      gsap.to('.sound-line', { y: 8 })

      videos.forEach((video) => {
        video.muted = true
      })
    } else {
      this.state.sound = true
      this.slides.current.muted = false
      if (this.state.isvisible) sound.play()

      videos.forEach((video) => {
        video.muted = false
      })
    }
  }

  on() {
    const { icon } = this.slides

    this.container.addEventListener('mousedown', this.onDown)
    this.container.addEventListener('mousemove', this.onMove)
    this.container.addEventListener('mouseup', this.onUp)
    this.container.addEventListener('touchstart', this.onDown)
    this.container.addEventListener('touchmove', this.onMove)
    this.container.addEventListener('touchend', this.onUp)
    icon.addEventListener('click', this.toggleSound)
  }

  init() {
    this.setup()
    this.on()
    this.playSound()
    this.visible()
  }
}
