import store from '../store'
import { Emitter } from '../core'
import { bounds } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'

export default class Marquee {
  constructor(obj = {}) {
    const els = obj.els
    this.x = 0

    this.dom = {
      els,
    }

    this.state = {
      active: false,
      speed: obj.speed || 300,
    }

    this.setup()
  }

  setup = () => {
    const { els } = this.dom
    const rect = bounds(els[0])
    const container = els[0].parentNode
    const bounding = bounds(container)
    const total = els.length
    const width = rect.width
    const end = width * total
    const side = bounding.left

    this.settings = {
      rect,
      width,
      end,
      total,
      bounding,
      side,
      ease: 0.1,
      container,
    }

    this.visible()
  }

  visible() {
    const { container } = this.settings

    ScrollTrigger.create({
      trigger: container,
      start: 'top top+=150%',
      end: 'bottom bottom-=150%',
      scrub: true,
      onEnter: () => {
        this.state.active = true
      },
      onEnterBack: () => {
        this.state.active = true
      },
      onLeaveBack: () => {
        this.state.active = false
      },
      onLeave: () => {
        this.state.active = false
      },
    })
  }

  run = () => {
    const { sniff } = store
    const { els } = this.dom
    const { width } = this.settings
    const { speed } = this.state
    const x = sniff.isDevice ? 1 : (width * 2 + 24) / speed

    if (!els[0] || !this.state.active) return

    this.x = this.x + x < width * 2 + 24 ? this.x + x : 0

    gsap.set(els, { x: `${this.x * -1}px` })
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('resize', this.setup)
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('resize', this.setup)
  }

  destroy() {
    this.off
  }
}
