import store from '../store'
import Highway from '@dogstudio/highway'
import { Core, Emitter } from '../core'
import {
  Splits,
  Menu,
  Smooth,
  Marquee,
  Slider,
  PillowAbout,
  CubeClients,
} from '../components'
import { qs, qsa, formFocus, growTextarea } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'

class Default extends Highway.Renderer {
  constructor(opt = {}) {
    super(opt)

    this.core = new Core()
    this.scroll = this.core.scroll.scroll
    this.state = {
      scroll: 0,
      last: 0,
      animate: false,
    }

    gsap.registerPlugin(ScrollTrigger)
  }

  onEnter() {
    const { sniff, body } = store
    if (sniff.isDevice) body.classList.add('is-device')
    store.page = this.wrap.lastElementChild
  }

  onEnterCompleted() {
    if (this.core.ready) this.ready()
    this.on()
  }

  ready = () => {
    const { animations } = this.core
    store.flags.locked = false

    this.getDom()
    this.components()
    animations.in()
    animations.onScroll()

    ScrollTrigger.refresh()
    // Add only if using Custom Scroll
    //this.smooth()
  }

  readyCompleted = () => {
    const { animations } = this.core

    this.marquees.forEach((item) => item.on())
  }

  getDom() {
    this.dom = {
      deskMenu: qs('.header-menu'),
      mobMenu: qs('.mobile-menu'),
      footer: qs('.footer'),
      open: qs('.burger'),
      close: qs('.close'),
      marquees: qsa('.marquee'),
      gotop: qs('.footer-gotop'),
      videos: qsa('[data-video]'),
    }
  }

  components() {
    const { sniff } = store
    const { location } = this.core.render
    const { deskMenu, mobMenu, footer, open, close, marquees, gotop, videos } =
      this.dom

    this.marquees = []

    this.spliText = new Splits()

    this.menu = new Menu({
      deskMenu,
      mobMenu,
      footer,
      url: location.href,
      open,
      close,
    })

    marquees.forEach((marquee, i) => {
      let items = qsa('.marquee-item', marquee)
      let speed = 400
      if (i == 0) speed = 600

      this.marquees.push(new Marquee({ els: items, speed: speed }))
    })

    this.slider = new Slider()
    this.cube = new CubeClients({ container: qs('.clients-list') })
    this.pillow = new PillowAbout({ container: qs('.about-asset') })

    gotop.addEventListener('click', () => this.scroll.scrollTo('#hero'))

    videos.forEach((video) => {
      const mp4 = video.dataset.mp4
      const webm = video.dataset.webm

      if (sniff.isSafari || sniff.isIOS) {
        video.src = mp4
      } else {
        video.src = webm
      }
    })
  }

  smooth() {
    formFocus()
    growTextarea()
    const smooth = qsa('[data-s-i]', this.el)
    this.core.scroll.setScrollBounds()
    Smooth.init(smooth)
  }

  tick = (e) => {
    const { sniff } = store

    this.state.scroll = this.scroll.scroll

    if (!sniff.isDesktop && !this.state.animate) {
      if (
        this.state.scroll > this.state.lastScroll &&
        this.state.scroll > 100
      ) {
        gsap.to('header', {
          duration: 0.5,
          y: '-100%',
          onComplete: () => {
            this.state.animate = false
          },
        })
        this.state.animate = true
      } else if (
        this.state.scroll < this.state.lastScroll &&
        this.state.scroll > 100
      ) {
        gsap.to('header', {
          duration: 0.5,
          y: '0%',
          onComplete: () => {
            this.state.animate = false
          },
        })
        this.state.animate = true
      }

      this.state.lastScroll = this.state.scroll
    }
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('loaded', this.ready)
    Emitter.on('animateIn:ended', this.readyCompleted)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('loaded', this.ready)
    Emitter.on('animateIn:ended', this.readyCompleted)
  }

  onLeave() {
    this.off()
    this.menu && this.menu.destroy()
  }

  onLeaveCompleted() {
    ScrollTrigger.getAll().forEach((inst) => inst.kill(true))
  }
}

export default Default
