import store from '../store'
import Default from './Default'

export default class Page extends Default {
  constructor(opt = {}) {
    super(opt)
    this.slug = 'page'
  }

  onEnter() {
    super.onEnter()
  }

  onEnterCompleted() {
    super.onEnterCompleted()
  }

  on() {
    super.on()
  }

  off() {
    super.off()
  }

  onLeave() {
    super.onLeave()
  }

  onLeaveCompleted() {
    super.onLeaveCompleted()
  }
}
