import Highway from '@dogstudio/highway'
import { gsap } from 'gsap'

export default class Fade extends Highway.Transition {
  in({ from, to, done }) {
    const tl = gsap.timeline({ paused: true })

    window.scrollTo(0, 0)
    from.remove()
    done()

    tl.fromTo(to, { autoAlpha: 1 }, { duration: 0.1, autoAlpha: 1 })

    tl.play()
  }

  out({ from, done }) {
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => done(),
    })

    tl.fromTo(from, { autoAlpha: 1 }, { duration: 0.1, autoAlpha: 1 })

    tl.play()
  }
}
