import store from '../store'
import Emitter from './Emitter'
import gsap from 'gsap'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class GlobalRaf {
  constructor() {
    gsap.registerPlugin(ScrollTrigger)

    this.scroll = {
      current: 0,
      limit: 0,
      velocity: 0,
      direction: 0,
      progress: 0,
    }

    this.mouse = {
      x: 0,
      y: 0,
      target: null,
    }

    // this.setScrollTrigger()
  }

  tick = (a, b, c) => {
    Emitter.emit('tick', {
      time: a,
      deltaTime: b,
      frame: c,
      scroll: this.scroll,
      mouse: this.mouse,
    })

    ScrollTrigger.update()
  }

  onScroll = ({ scroll, limit, velocity, direction, progress }) => {
    this.scroll = {
      current: scroll,
      limit,
      velocity,
      direction,
      progress,
    }
  }

  move = ({ x, y, target }) => {
    this.mouse.x = x
    this.mouse.y = y
    this.mouse.target = target
  }

  setScrollTrigger() {
    const { body, sizes } = store

    ScrollTrigger.defaults({
      scroller: body,
    })

    ScrollTrigger.scrollerProxy(body, {
      scrollTop: () => {
        return this.scroll.current
      },
      getBoundingClientRect() {
        return { top: 0, left: 0, width: sizes.vw, height: sizes.vh }
      },
    })
  }

  on() {
    gsap.ticker.add(this.tick)
    Emitter.on('scroll', this.onScroll)
    Emitter.on('mousemove', this.move)
  }
}
