import gsap from 'gsap'
import { qs } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export const animateScroll = (animation) => {
  switch (animation) {
    case 'about':
      const el = qs('.about')

      const tl = gsap.timeline({ paused: true })

      tl.from('.about-description', {
        duration: 1,
        y: '-100%',
        ease: 'power2.inOut',
      })
      tl.from(
        '.about .description-content',
        { duration: 1, y: '100%', ease: 'power2.inOut' },
        '-=1',
      )

      tl.from('.about-icon .-contain', { duration: 1, scale: 0 }, '-=0.5')

      tl.from(
        '.about-txt .word-0',
        {
          duration: 0.5,
          autoAlpha: 0,
          y: -20,
          stagger: 0.035,
        },
        '-=0.8',
      )

      const about = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top top+=65%',
          end: 'bottom bottom+=5%',
          scrub: true,
          once: true,
        },
      })

      about.from('.about .asset-img', { duration: 0.8, scale: 0 })
      about.from(
        '.asset-img .line',
        {
          duration: 1,
          opacity: 0,
          scale: 0,
          stagger: 0.1,
          transformOrigin: 'center',
          onComplete: () => {
            gsap.to('.asset-img svg', {
              duration: 0.5,
              autoAlpha: 0,
              delay: 0.25,
            })

            tl.play()
          },
        },
        '-=0.6',
      )

      break

    case 'work':
      const work = gsap.timeline({
        scrollTrigger: {
          trigger: '.work',
          start: 'top top+=70%',
          end: 'top top+=50%',
          scrub: true,
        },
      })

      work.to('body', {
        duration: 0.5,
        background: '#141414',
      })

      const work1 = gsap.timeline({
        scrollTrigger: {
          trigger: '.work',
          start: 'top top+=70%',
          end: 'bottom bottom',
        },
      })

      work1.from('.work .marquee', { duration: 0.1, autoAlpha: 0, delay: 1 })
      work1.from('.work .-mask-left', { duration: 1, x: 0 })
      work1.from('.work .-mask-right', { duration: 1, x: 0 }, '-=1')
      work1.from('.work .-mask-top', { duration: 1, y: 0 }, '-=1')
      work1.from('.work .-mask-bottom', { duration: 1, y: 0 }, '-=1')

      const work2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.work',
          start: 'top top',
          end: 'bottom bottom+=400',
          scrub: true,
          once: true,
        },
      })

      work2.from('.cards-item', { y: '100%', rotate: 0, stagger: 0.1 })

      break

    case 'web3':
      const web3 = gsap.timeline({
        scrollTrigger: {
          trigger: '.web3',
          start: 'top top+=50%',
          end: 'bottom bottom',
          scrub: true,
          once: true,

          onUpdate: (self) => {
            if (self.progress.toFixed(1) == 0.5) {
            }
          },
        },
      })

      web3.from('.web3-project', { duration: 3, scale: 0 }, 0)

      web3.from('.project-footer', { duration: 1, x: '-100%' }, 0.8)
      web3.from('.project-icon', { duration: 1, x: '100%' }, 0.8)
      web3.from(
        '.project-description .word-0',
        { duration: 1, autoAlpha: 0, stagger: 0.01, delay: 0.5 },
        1,
      )

      web3.from(
        '.project-title .char-0',
        { duration: 1, autoAlpha: 0, stagger: 0.05, delay: 0.5 },
        1,
      )
      web3.from('.web3 .circle', { duration: 1, autoAlpha: 0, delay: 0.5 }, 1)

      break

    case 'clients':
      const clients = gsap.timeline({
        scrollTrigger: {
          trigger: '.clients',
          start: 'top top+=50%',
        },
      })

      clients.from('.clients .-mask-left', { duration: 1, x: 0 })
      clients.from('.clients .-mask-right', { duration: 1, x: 0 }, '-=1')
      clients.from('.clients-bg', { duration: 1, y: '100%' }, '-=1')
      clients.from('.clients .-asset-crop', { duration: 1, y: '-100%' }, '-=1')

      break

    case 'footer':
      const footer = gsap.timeline({
        scrollTrigger: {
          trigger: '.footer',
          start: 'top top+=80%',
          end: 'bottom bottom-=80%',
          scrub: true,
          onEnter: () => {
            // gsap.set('.clients .-asset-crop', { autoAlpha: 0 })

            gsap.to('.clients-list .-asset-crop', { duration: 0.5, y: '-101%' })
            gsap.to('.clients-list img', { duration: 0.5, y: '101%' }, '-=0.5')
          },

          onUpdate: (self) => {
            if (self.progress === 0) {
              // gsap.set('.clients .-asset-crop', { autoAlpha: 1 })

              gsap.to('.clients-list .-asset-crop', { duration: 0.5, y: '-0%' })
              gsap.to('.clients-list img', { duration: 0.5, y: '0%' }, '-=0.5')
            }
          },
        },
      })

      footer.to('.clients .clients-bg', {
        duration: 0.8,
        scaleX: 1.2,
        scaleY: 11,
      })

      const footer2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.footer',
          start: 'top top+=40%',
        },
      })

      // footer2.from('.footer', {
      //   duration: 0.5,
      //   background: '#141414',
      // })

      footer2.from(
        '.footer .-t4 .char-0',
        {
          duration: 0.3,
          y: 20,
          autoAlpha: 0,
          stagger: 0.025,
        },
        //'-=0.5',
      )

      footer2.from(
        '.footer .hand',
        {
          duration: 0.5,
          rotate: -20,
          y: 20,
          autoAlpha: 0,
        },
        '-=0.8',
      )

      footer2.from(
        '.footer .-p .char-0',
        {
          duration: 0.5,
          y: 8,
          autoAlpha: 0,
          stagger: 0.025,
        },
        '-=1',
      )

      footer2.from(
        '.logo .logo-lines',
        {
          duration: 0.1,
          autoAlpha: 0,
        },
        '-=0.5',
      )

      footer2.to(
        '.logo .logo-lines',
        {
          duration: 0.1,
          autoAlpha: 0,
        },
        '-=0.4',
      )

      footer2.to(
        '.logo .logo-lines',
        {
          duration: 0.1,
          autoAlpha: 1,
        },
        '-=0.3',
      )

      footer2.to(
        '.logo .logo-lines',
        {
          duration: 0.1,
          autoAlpha: 0,
        },
        '-=0.2',
      )

      footer2.to(
        '.logo .logo-lines',
        {
          duration: 0.1,
          autoAlpha: 1,
        },
        '-=0.1',
      )

      footer2.to(
        '.logo .logo-full',
        {
          duration: 0.1,
          autoAlpha: 1,
        },
        '-=0.05',
      )

      footer2.to('.logo .logo-full', {
        duration: 0.1,
        autoAlpha: 1,
        onComplete: () => {
          gsap.set('.logo .logo-full', { scaleX: 1 })
          gsap.set('.logo .logo-lines', { autoAlpha: 0 })
        },
      })

      break
  }
}
