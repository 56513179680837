import store from '../store'
import * as THREE from 'three'
import { Emitter } from '../core'
import { qs, bounds } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import {
  WebGLRenderer,
  PerspectiveCamera,
  BoxGeometry,
  Scene,
  Mesh,
} from 'three'
import gsap from 'gsap'

export default class CubeClients {
  constructor(obj = {}) {
    const container = obj.container || store.body
    const { sniff } = store

    this.scene = {
      name: 'Default',
      renderer: null,
      camera: null,
      objects: null,
      orbit: null,
      container,
      multiplier: sniff.isDesktop ? 250 : 100,
    }

    this.state = {
      active: false,
    }

    this.mouse = {}

    this.time = 0
    this.init()
  }

  setup() {
    this.render()
    this.camera()
    this.mesh()
  }

  render() {
    const { container, multiplier } = this.scene
    const { sizes } = store
    const dpr = window.devicePixelRatio ? window.devicePixelRatio : 1
    const rect = bounds(container)
    const renderer = new WebGLRenderer({
      antialias: true,
      alpha: true,
      setPixelRatio: dpr,
    })

    renderer.setClearColor(0x000000, 0)
    renderer.domElement.classList.add('gl-cube')
    renderer.setSize(rect.width + multiplier, rect.height + multiplier)
    container.appendChild(renderer.domElement)

    Object.assign(this.scene, {
      renderer,
      rect,
    })
  }

  camera() {
    const { sizes } = store
    const { renderer, rect, multiplier } = this.scene

    const aspectRatio = rect.width + multiplier / rect.height + multiplier
    const camera = new PerspectiveCamera(35, aspectRatio, 0.1, 100)

    camera.position.set(0, 1, 7)
    camera.lookAt(0, 0, 0)
    const orbit = new OrbitControls(camera, renderer.domElement)

    //orbit.enabled = false
    orbit.enableZoom = false

    Object.assign(this.scene, {
      camera,
      orbit,
    })
  }

  mesh() {
    const { breakpoints } = store
    const { renderer } = this.scene
    const textures = qs('[data-faces]').dataset.faces.split(',')
    const loader = new THREE.TextureLoader()
    const background = loader.load(textures[0])
    const materials = []

    let scale

    for (var i = 0; i < 6; i++) {
      let bg = textures[i]

      const texture = loader.load(bg)

      texture.anisotropy = renderer.capabilities.getMaxAnisotropy()

      materials.push(new THREE.MeshBasicMaterial({ map: texture }))
    }

    const objects = new Scene()
    const cubeGeometry = new BoxGeometry(2, 2, 2)
    const cube = new Mesh(cubeGeometry, materials)

    cube.position.set(0, 0, 0)
    cube.rotation.x = 1.2
    cube.rotation.z = 1.2
    cube.name = 'Cube'

    if (breakpoints.M_UP) {
      scale = 1.25
    } else {
      scale = 0.9
    }

    objects.scale.set(scale, scale, scale)

    objects.add(cube)

    Object.assign(this.scene, {
      objects,
    })
  }

  tick = ({ mouse }) => {
    const { renderer, camera, objects, orbit } = this.scene

    if (!this.state.active) return

    this.mouse = mouse

    this.time += 1 / 300
    orbit.update()
    renderer.render(objects, camera)
  }

  visible() {
    const { container } = this.scene
    const { objects } = this.scene

    ScrollTrigger.create({
      trigger: container,
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      scrub: true,
      onEnter: () => {
        this.state.active = true
      },
      onEnterBack: () => {
        this.state.active = true
      },
      onLeaveBack: () => {
        this.state.active = false
      },
      onLeave: () => {
        this.state.active = false
      },
    })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: 'top top+=100%',
        end: 'bottom bottom',
        scrub: true,
        once: true,
      },
    })

    tl.from(objects.scale, {
      y: 0,
      x: 0,
      z: 0,
    })

    // const tl2 = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.footer',
    //     start: 'top top+=80%',
    //     end: 'top top',
    //     scrub: true,
    //   },
    // })

    // tl2.to(objects.position, {
    //   y: 3,
    //   z: -1,
    // })
  }

  move = () => {
    const { sizes } = store
    const { objects } = this.scene

    const x = this.mouse.x
    const y = this.mouse.y
    const xrange = gsap.utils.mapRange(0, sizes.vw, 0.2, -0.2, x)
    const yrange = gsap.utils.mapRange(0, sizes.vh, 0.2, -0.2, y)

    gsap.to(objects.rotation, {
      y: xrange,
      x: yrange,
    })
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.resize)
    window.addEventListener('mousemove', this.move)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('resize', this.resize)
    window.removeEventListener('mousemove', this.move)
  }

  resize = () => {
    const { sizes, breakpoints } = store
    const { objects, container, multiplier } = this.scene
    const rect = bounds(container)
    const aspectRatio = (rect.width + multiplier) / (rect.height + multiplier)

    let scale

    this.scene.camera.aspect = aspectRatio
    this.scene.camera.updateProjectionMatrix()
    this.scene.renderer.setSize(
      rect.width + multiplier,
      rect.height + multiplier,
    )

    if (breakpoints.M_UP) {
      scale = 1.25
    } else {
      scale = 0.9
    }

    objects.scale.set(scale, scale, scale)
  }

  init() {
    this.setup()
    this.on()
    this.visible()
    this.resize()
  }
}
